import React from "react";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import Twitter from "../../../../assets/images/twitter.png";
import socialmedia from "../../../../assets/images/social-media.png";
import contactform from "../../../../assets/images/form.png";
import Header from "../../../../containers/Header";

import gmail from "../../../../assets/images/gmail.png";
import phonecall from "../../../../assets/images/phone-call.png";
import Facebook from "../../../../assets/images/facebook.png";
import livechat from "../../../../assets/images/live-chat.png";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { useSelector } from "react-redux";

import { t } from "i18next";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

const ContactUsComponent = ({ isAuth }) => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <div>
      <div>{isAuth ? <HeaderAfterLogin /> : <Header />}</div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 className="sectionTitle">{t("contact_us")}</h2>
            <div className="payment-info">
              <div class="social-icons contact-social d-none">
                <ul>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {/* <span className="notification-count">2</span> */}
                      <img src={Whatsapp} alt="Whatsapp Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src={Facebook} alt="Telegram Icon" className="" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails.INSTATGRAM != ""
                          ? appDetails.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li>
                  <li>
                    <a className="panel" href="#">
                      <img src={Twitter} alt="Twitter Icon" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="other-contact">
                <p className="foloowingpoints">
                  {t("contact_us_p_one")}
                  <p className="mt-5">
                    <img src={livechat} className="contact_icons" />{" "}
                    {t("contact_us_p_two")}
                  </p>
                  <p className="mt-5">
                    <img src={gmail} className="contact_icons" />{" "}
                    {t("contact_us_p_three", {
                      email: "dial4win@gmail.com",
                    })}
                  </p>{" "}
                  <p className="mt-5 social">
                    <img src={socialmedia} className="contact_icons" />
                    <> {t("contact_us_p_four")} </>
                    <a
                      className="panel"
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src={Facebook} alt="Telegram Icon" className="" />
                    </a>
                    <a
                      className=""
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" className="" />
                    </a>
                    <a href="https://twitter.com/Dial4W133">
                      {" "}
                      <img src={Twitter} className="" />
                    </a>{" "}
                    {t("contact_us_p_five")}
                  </p>
                  <p className="mt-5">
                    <img src={contactform} className="contact_icons" />
                    {t("contact_us_p_six")}
                  </p>
                  <p className="mt-5 px-0">{t("contact_us_p_seven")}</p>
                  <p className="mt-5 px-0">{t("contact_us_p_eight")}</p>
                  <p className="mt-5 px-0">{t("contact_us_p_nine")}</p>
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>{!isAuth ? <BeforeLoginFooter /> : <Footer />}</div>
    </div>
  );
};

export default ContactUsComponent;

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MainSlider = ({ banners }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const settings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    // autoplaySpeed: true,
    slidesToShow: isAuth ? 2 : 1,
    slidesToScroll: 1,
    // autoplaySpeed: true,
    speed: 5000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: isAuth ? 2 : 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: isAuth ? 2 : 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: isAuth ? 2 : 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="position-relative" key={index}>
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        {(b?.ctabtns.length &&
                          b.ctabtns.map((c, i) => {
                            return (
                              <Button
                                variant="primary"
                                className="button-primary"
                                onClick={() => {
                                  window.location.href = c.actionLink;
                                }}
                              >
                                {c.label}
                              </Button>
                            );
                          })) ||
                          null}
                      </div>
                    </div>
                  </div>
                </div>
                <img src={b.link} alt="First Slide" />
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainSlider;

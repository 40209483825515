import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftSideComponents from "../../../components/LeftSideComponents";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const KioskLocator = () => {
  const navigate = useNavigate();
  const kioskUrl =
    "https://www.google.com/maps/d/embed?mid=1XCXn0gwtej2a2QkE1A4TtlxmZTc&ehbc=2E312F";
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="transfer_info_page">
          <Container>
            <div className="PageSection">
              <LeftSideComponents />

              <div className="PageRightBar">
                <div className="RightBarHead d-flex justify-content-between align-items-center">
                  <h2>{t("kiosk_locator")}</h2>
                  <div className="back-link" onClick={() => navigate(-1)}>
                    Back <BackIcon />
                  </div>
                </div>
                <div>
                  <div className="">
                    <div>
                      <iframe
                        title="Kiosk Locator"
                        src={kioskUrl}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: "calc(100vh - 67px)",
                        }}
                        // scrolling="no"
                        allowFullScreen={true}
                      >
                        Your browser doesn't support iFrames.
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
    </div>
  );
};

export default KioskLocator;

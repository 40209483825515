import React, { Suspense, useEffect } from "react";
import "./App.scss";
import "./i18n";
import Footer from "./containers/Footer";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";
import ContactUs from "./views/pages/privatePages/FooterPages/ContactUs";
import Contact from "./views/components/Contact.js";
import PrivacyPolicy from "./views/pages/privatePages/FooterPages/PrivacyPolicy";
import ResponsibleGame from "./views/pages/privatePages/FooterPages/ResponsibleGame";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import GamesRule from "./views/pages/privatePages/FooterPages/GamesRule";
import Terms from "./views/pages/privatePages/FooterPages/Terms";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeFairPlay from "./views/pages/publicPages/FooterPages/BeforeFairPlay";
import BeforeGamesRule from "./views/pages/publicPages/FooterPages/BeforeGamesRule";
import BeforePrivacyPolicy from "./views/pages/publicPages/FooterPages/BeforePrivacyPolicy";
import BeforeResponsibleGame from "./views/pages/publicPages/FooterPages/BeforeResponsibleGame";
import BeforeTerms from "./views/pages/publicPages/FooterPages/BeforeTerms";
import TestSportsBook from "./views/pages/privatePages/sportsBook/testSportsBook";
import LoaderImg from "./assets/images/loading-icon1.gif";
import Casino from "./views/pages/publicPages/Casino";
import CasinoAfter from "./views/pages/privatePages/CasinoAfter";
import CasinoBet from "./views/pages/privatePages/CasinoBet";
import KYC from "./views/pages/privatePages/KYC";
import Promotion from "./views/pages/privatePages/Promotion";
import SportsBet from "./views/pages/privatePages/SportsBet";
import Depositrequest from "./views/pages/privatePages/deposit/Depositrequest.js";
import Bonus from "./views/pages/privatePages/bonus";
import BonusCard from "./views/pages/privatePages/bonus/bonusCard";
import Referral from "./views/pages/privatePages/referral";
import AboutUs from "./views/pages/publicPages/FooterPages/AboutUs";
import AboutUss from "./views/pages/privatePages/FooterPages/AboutUss";
import Rules from "./views/pages/privatePages/FooterPages/Rules";
import RulesRegulation from "./views/pages/publicPages/FooterPages/RulesRegulation";
import LandingPAge from "./views/components/LandingPAge";
import Header from "./containers/Header";

import PromotionBefore from "./views/pages/publicPages/PromotionBefore";
import FirstPromo from "./views/pages/privatePages/Promotion/FirstPromo";
import SecondPromo from "./views/pages/privatePages/Promotion/SecondPromo";
import ThirsPromo from "./views/pages/privatePages/Promotion/ThirsPromo";
import ReferralPromo from "./views/pages/privatePages/Promotion/ReferralPromo";
import WeekendPromo from "./views/pages/privatePages/Promotion/WeekendPromo";
import SigupPromo from "./views/pages/privatePages/Promotion/SigupPromo";

import Slots from "./views/pages/privatePages/Slots";

import ScrolltoTop from "./ScrollToTop.js";
import ContactUsComponent from "./views/pages/publicPages/FooterPages/BeforeContactUs";
import KioskLocator from "./views/pages/privatePages/kioskLocator/index.js";

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);

const SendOtpToEmail = React.lazy(() =>
  import("./views/pages/publicPages/register/sendOtpToEmail")
);

const VerifyEmail = React.lazy(() =>
  import("./views/pages/publicPages/register/emailVerify")
);

const Blog = React.lazy(() => import("./views/pages/privatePages/Blog"));
const ViewBlog = React.lazy(() =>
  import("./views/pages/privatePages/Blog/viewBlog")
);
const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppDetails());
  }, []);
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
  }, [appDetails]);
  return (
    <Router>
      <ScrolltoTop />
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />
      <Suspense
        fallback={
          <div className="loader-img">
            <img src={LoaderImg} alt="loader" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/landing-page"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/depositlist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Depositrequest />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route path="/blog" element={<Blog isAuth={isAuth} />} />
          <Route path="/blog/:key" element={<ViewBlog isAuth={isAuth} />} />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbet"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SportsBet />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawhistory"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/sportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/kyc"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <KYC />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/kiosklocator"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <KioskLocator />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/promotion"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Promotion />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/promotions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <PromotionBefore />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/firstpromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FirstPromo isAuth={isAuth} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/secondpromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SecondPromo />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/thirdpromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ThirsPromo />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referralpromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ReferralPromo />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/weekendpromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WeekendPromo />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/signuppromo"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SigupPromo />
              </ProtectedRoutes>
            }
          />
          <Route path="/firstpromos" element={<FirstPromo isAuth={isAuth} />} />
          <Route
            path="/secondpromos"
            element={<SecondPromo isAuth={isAuth} />}
          />
          <Route path="/thirdpromos" element={<ThirsPromo isAuth={isAuth} />} />
          <Route
            path="/referralpromos"
            element={<ReferralPromo isAuth={isAuth} />}
          />
          <Route
            path="/weekendpromos"
            element={<WeekendPromo isAuth={isAuth} />}
          />
          <Route
            path="/signuppromos"
            element={<SigupPromo isAuth={isAuth} />}
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Betby />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Casioafter"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <CasinoAfter />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/exchange"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </PublicRoutes>
            }
          />
          <Route
            path="/Casino"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Casino />
              </PublicRoutes>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          {/* <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          /> */}
          {/* <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          /> */}
          <Route
            path="/user-email"
            element={
              <PublicRoutes isAuth={isAuth}>
                <SendOtpToEmail />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-email"
            element={
              <PublicRoutes isAuth={isAuth}>
                <VerifyEmail />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route path="test" element={<Sports />} />
          <Route
            // path="/sports/exchange"
            path="/sports"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <>
                <MatchDetailPage isAuth={isAuth} />
                {isAuth ? <Footer /> : <BeforeLoginFooter />}
              </>
            }
          />
          {/* <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          /> */}
          <Route path="/contactus" element={<Contact isAuth={isAuth} />} />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Terms />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/AboutUss"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <AboutUss />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/rules"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Rules />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/contactuss"
            element={<ContactUsComponent isAuth={isAuth} />}
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePrivacyPolicy />
              </PublicRoutes>
            }
          />

          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />

          <Route
            path="/responsiblegames"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/gamesrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGamesRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/termss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTerms />
              </PublicRoutes>
            }
          />
          <Route
            path="/aboutus"
            element={
              <PublicRoutes isAuth={isAuth}>
                <AboutUs />
              </PublicRoutes>
            }
          />
          <Route
            path="/rules&regulation"
            element={
              <PublicRoutes isAuth={isAuth}>
                <RulesRegulation />
              </PublicRoutes>
            }
          />
          <Route
            path="/sportsbooktest"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TestSportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casinobet"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <CasinoBet />
                <div className="OtherFooter">
                  <Footer />
                </div>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

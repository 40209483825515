import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";

import gmail from "../../assets/images/gmail.png";
import livechat from "../../assets/images/live-chat.png";
import Instagram from "../../assets/images/Instagram.svg";
import Twitter from "../../assets/images/twitter.png";
import Facebook from "../../assets/images/facebook.png";
import socialmedia from "../../assets/images/social-media.png";
import contactform from "../../assets/images/form.png";
import { Trans } from "react-i18next";

const Contact = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <main className="main pagecontent my-0" style={{ minHeight: "100vh" }}>
        <div className="container">
          <h2 className="sectionTitle">Contact Us</h2>
          <div className="payment-info">
            <div className="other-contact">
              <p className="foloowingpoints">
                We appreciate your interest in our Gaming website and value your
                feedback. If you have any questions, concerns, or suggestions,
                our customer support team is here to assist you.There are
                several ways you can reach out to us:
                <p className="mt-5">
                  <img src={livechat} className="contact_icons" /> Visit our
                  website and look for the live chat feature located in the
                  bottom corner of the screen. Our knowledgeable support agents
                  will be available to provide real-time assistance during our
                  designated operating hours.
                </p>
                <p className="mt-5">
                  <img src={gmail} className="contact_icons" /> You can send us
                  an email at{" "}
                  <a href="mailto:dial4win@gmail.com">dial4win@gmail.com</a>.
                  Please provide detailed information regarding your inquiry or
                  concern, along with your contact details. We strive to respond
                  to all emails promptly.,
                  {/* <a href="mailto:dial4win@gmail.com">dial4win@gmail.com</a> */}
                </p>{" "}
                <p className="mt-5 social">
                  <img src={socialmedia} className="contact_icons" />
                  <>
                    {" "}
                    Social Media: You can also reach out to us through our
                    official social media channels. Follow us on{" "}
                  </>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook} alt="Telegram Icon" className="" />
                  </a>
                  <a
                    className=""
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" className="" />
                  </a>
                  <a href="https://twitter.com/Dial4W133">
                    {" "}
                    <img src={Twitter} className="" />
                  </a>{" "}
                  and send us a direct message with your query. We aim to
                  respond to social media inquiries within a reasonable time
                  frame.
                </p>
                <p className="mt-5">
                  <img src={contactform} className="contact_icons" />
                  Contact Form: Alternatively, you can fill out the contact form
                  available on our website. Provide the necessary details and a
                  clear description of your request, and we will get back to you
                  as soon as possible.
                </p>
                <p className="mt-5 px-0">
                  Please include any relevant account information or details
                  related to your inquiry to help us assist you more
                  efficiently.
                </p>
                <p className="mt-5 px-0">
                  Our customer support team operates 24*7. We are dedicated to
                  providing excellent service and resolving your concerns in a
                  timely manner.
                </p>
                <p className="mt-5 px-0">
                  Thank you for choosing our Gaming website. We look forward to
                  assisting you and ensuring you have an enjoyable and seamless
                  Gaming experience.
                </p>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Contact;

import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";

import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/icons/telegramicon.png";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/icons/instagramicon.png";
import FACEBOOK from "../assets/images/icons/facebookicon.png";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/GamblingCOmmission.jpg";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import Accordion from "react-bootstrap/Accordion";
import GamesAfterIcon from "../assets/images/chip.png";
import DepositIcon1 from "../assets/images/savings.png";
import Exchange from "../assets/images/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/footfav1.png";
import Support from "../assets/images/games-icon/support.png";

import FAQImg from "../assets/images/faq/faq-img.svg";
import faqAnimation from "../assets/images/faq/faq-animation.svg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import rng from "../assets/images/providers/RNG.png";
import plus from "../assets/images/providers/GamblingCOmmission.jpg";
import GamblingCOmmission from "../assets/images/providers/GamingResposibly.jpg";
import RGF from "../assets/images/providers/RGF.png";

import ProviderLogo4 from "../assets/images/providers/Spribe.jpg";
import ProviderLogo5 from "../assets/images/providers/netent.jpg";
import { Col, Row } from "react-bootstrap";
import PaymentMod1 from "../assets/images/providers/visa.png";
import PaymentMod12 from "../assets/images/providers/bitcoin.png";
import Twitter from "../assets/images/icons/twitter.png";

import i18next, { t } from "i18next";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer main">
      <div className="container">
        <div className="providers_sec">
          <Row>
            <Col xs={12}>
              <div className="d-xl-flex align-items-center justify-content-center">
                <div className="heading mb-4 mb-xl-0 d-none">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>Play Your favorite Games Games with Top Providers</p>
                  </div>
                </div>

                <div className="providers_logo d-flex  justify-content-between">
                  <div className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </div>
                  {/* <div className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </div> */}
                  <div className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={rng} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={plus} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={GamblingCOmmission} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={RGF} alt="Provider Logo" />
                  </div>
                  {/* <div className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </div>
                  <div" className="logo_box">
                    <img src={ProviderLogo6} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo7} alt="Provider Logo" />
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="providers_img d-none">
          <a href="#" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="#" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="#" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="#" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "#"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            Call Us {appDetails?.WHATS_APP_URL}
          </a>
          {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
        </div>

        <div className="footer-links">
          <div className="FooterPart">
            <h3>{t("about_us")}</h3>
            <ul>
              <li>
                <a href="/aboutus">{t("about_us")}</a>
              </li>
              <li>
                <a href="/responsiblegames">{t("responsible_gaming")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("help")}</h3>
            <ul>
              <li>
                <a href="/contactuss">{t("contact_us")}</a>
              </li>
              {/* <li>
                <a href="/contactuss">{t("contact_us")}</a>
              </li> */}
              <li>
                <a href="/termss">{t("terms_conditions")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("rules_regiulations")}</h3>
            <ul>
              <li>
                <a href="/privacypolicys ">{t("privacy_policy")}</a>
              </li>
              <li>
                <a href="/fairplays">{t("fair_play")}</a>
              </li>
              <li>
                <a href="/rules&regulation">{t("rules_regiulations")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("social")}</h3>

            <ul>
              <li>
                <a
                  className="panel"
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FACEBOOK} alt="Telegram Icon" />
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href="https://twitter.com/Dial4W133"
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Twitter} alt="Telegram Icon" />
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                  <span>Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <img
            src={PaymentMod1}
            className="mx-3"
            loading="lazy"
            style={{ width: "100px" }}
            alt="Payment Otions"
          />
          <img
            src={PaymentMod12}
            className="mx-3"
            loading="lazy"
            style={{ width: "100px" }}
            alt="Payment Otions"
          />
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3 d-none">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-none d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/");
                }}
              >
                {/* <SportsIcon /> */}
                <img src={Exchange} alt="Exchange Icon" />
                <span> {t("sportsbook")}</span>
              </a>
            </li>

            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/Games");
                }}
              >
                {/* <GamesIcon /> */}
                <img src={GamesAfterIcon} alt="Exchange Icon" />
                <span>{t("games")}</span>
              </a>
            </li>
            <li className="active-foot">
              <a className="active" href="/landing-page">
                {/* <HomeIcon /> */}
                <img src={HomeIcon1} />
                {/* <span>Home</span> */}
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                {/* <CashierIcon /> */}
                <img src={DepositIcon1} alt="Exchange Icon" />
                <span>{t("deposit")}</span>
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                {/* <CashierIcon /> */}
                <img src={Support} />
                <span>{t("support")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p>{appDetails?.FOOTER_TEXT || ""}</p>
    </footer>
  );
};

export default BeforeLoginFooter;

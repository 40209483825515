import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import { t } from "i18next";

function FairPlay() {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 class="sectionTitle">{t("fair_play")}</h2>
            <div className="fairplay sub-points">
              <p>{t("fair_play_p_one")}</p>
              <p>
                <span>{t("fair_play_span_one")} - </span>
                {t("fair_play_p_two")}
              </p>

              <p>
                {" "}
                <span>{t("fair_play_span_two")}: -</span>{" "}
                {t("fair_play_p_three")}
              </p>
              <p>
                <span>{t("fair_play_span_three")} -</span>{" "}
                {t("fair_play_p_four")}
              </p>
              <p>
                <span>{t("fair_play_span_four")} -</span>{" "}
                {t("fair_play_p_five")}{" "}
              </p>
              <p>
                <span> {t("fair_play_span_five")} -</span>{" "}
                {t("fair_play_p_six")}
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default FairPlay;

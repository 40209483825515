import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftSideComponents from "../../../components/LeftSideComponents";

import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";

import BackIcon from "../../../../assets/svg/BackIcon";
import DepositIcon1 from "../../../../assets/images/games-icon/Deposit.png";
import WithdrawIcon1 from "../../../../assets/images/games-icon/Withdraw1.png";
import ProfileIcon1 from "../../../../assets/images/games-icon/profile1.png";
import AccountIcon1 from "../../../../assets/images/games-icon/account-statement1.png";
import SignOut12 from "../../../../assets/images/games-icon/sign-out1.png";
import { logoutUser } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import {
  getPlayerKycRecordAction,
  submitKycDocumentAction,
} from "../../../../redux/user/actions";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import ListKycDocument from "./listKycDoc";

const KYC_DOCUMENTTYPE = {
  PASSPORT: "passport",
  EMIRATES_ID: "emiratesId",
};

const KYC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [kycFormData, setKycFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [kycRecord, setKycRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmittingDocument, setIsSubmittingDocument] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");

  useEffect(() => {
    fetchPlayerKycRecord();
  }, []);

  const fetchPlayerKycRecord = async () => {
    setIsLoading(true);
    const playerKycRecord = await getPlayerKycRecordAction();
    setIsLoading(false);
    if (playerKycRecord?.data?.data) {
      setKycRecord(playerKycRecord?.data?.data);
    }
  };
  const validateData = () => {
    const errorsObject = {};

    const { documentFile } = kycFormData;
    if (!documentFile) {
      errorsObject["documentFile"] = "Document is required";
    }
    if (!expiryDate) {
      errorsObject["expiry"] = "Expiry date is required";
    }
    setFormErrors(errorsObject);
    return errorsObject;
  };
  const handleKycDocumentSubmit = async (documentType) => {
    const errors = validateData();
    if (Object.keys(errors).length === 0) {
      let dataToApi = {
        type: documentType,
        expiry: expiryDate,
        ...kycFormData,
      };
      setIsSubmittingDocument(true);
      const documentResponse = await submitKycDocumentAction(dataToApi);
      setIsSubmittingDocument(false);
      if (documentResponse) {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Kyc document uploaded");
        fetchPlayerKycRecord();
      }
    }
  };

  const handleChange = (event, type = "text") => {
    const name = event.target.name;
    console.log("name", name);
    const value = type === "text" ? event.target.value : event.target.files[0];
    let formData = { ...kycFormData, [name]: value };
    setKycFormData({ ...formData });
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="transfer_info_page">
          <Container>
            <div className="PageSection">
              <LeftSideComponents />

              <div class="PageLeftBar d-none">
                <div className="LeftBarSide">
                  <ul>
                    <li>
                      <a href="/deposit">
                        <img src={DepositIcon1} />
                        <span>Deposit</span>
                      </a>
                    </li>
                    <li>
                      <a href="/withdraw">
                        <img src={WithdrawIcon1} />
                        <span>Withdraw</span>
                      </a>
                    </li>
                    <li>
                      <a href="/profile">
                        <img src={ProfileIcon1} />
                        <span>Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="/cashier">
                        <img src={AccountIcon1} />
                        <span>Transaction</span>
                      </a>
                    </li>
                    <li>
                      <a href="/kyc">
                        <img src={AccountIcon1} />
                        <span>KYC</span>
                      </a>
                    </li>
                    <li>
                      <a href="/promotion">
                        <img src={AccountIcon1} />
                        <span>Promotion</span>
                      </a>
                    </li>
                    <li onClick={() => dispatch(logoutUser())}>
                      <a href="/">
                        <img src={SignOut12} />
                        <span>Sign Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="PageRightBar">
                <div className="RightBarHead d-flex justify-content-between align-items-center">
                  <h2>KYC</h2>
                  <div className="back-link" onClick={() => navigate(-1)}>
                    Back <BackIcon />
                  </div>
                </div>
                <div className="tabs-area">
                  <Tabs
                    defaultActiveKey="first"
                    onSelect={() => {
                      setExpiryDate("");
                      setKycFormData({});
                    }}
                  >
                    <Tab eventKey="first" title="PASSPORT">
                      <Row className="mb-4">
                        <Col lg={12}>
                          <Form>
                            <Form.Group
                              className="form-group mb-3"
                              controlId="formGroupAmount"
                            >
                              <div class="file inputfile">
                                <Form.Control
                                  type="file"
                                  name="documentFile"
                                  onChange={(e) => {
                                    handleChange(e, "file");
                                  }}
                                  isInvalid={!!formErrors["documentFile"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formErrors?.documentFile}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="form-group mb-3"
                              controlId="formGroupAmount"
                            >
                              <Form.Control
                                type="date"
                                name="expiry"
                                min={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                  setExpiryDate(Date(e));
                                }}
                                isInvalid={!!formErrors["expiry"]}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors?.expiry}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form>
                          <Col xs={12} className="text-center">
                            <button
                              className="btn btn-primary py-2 px-5"
                              onClick={() =>
                                handleKycDocumentSubmit(
                                  KYC_DOCUMENTTYPE.PASSPORT
                                )
                              }
                              disabled={isSubmittingDocument}
                            >
                              {isSubmittingDocument ? "Loading..." : "Submit"}
                            </button>
                          </Col>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="second" title="EMIRATES ID">
                      <Row className="mb-4">
                        <Col lg={12}>
                          <Form>
                            <Form.Group
                              className="form-group mb-3"
                              controlId="formGroupAmount"
                            >
                              {/* <Form.Label>Enter Id</Form.Label> */}
                              <Form.Control
                                type="text"
                                placeholder="Enter Id"
                                name="documentId"
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <Form.Group
                              className="form-group mb-3"
                              controlId="formGroupAmount"
                            >
                              <div class="file inputfile">
                                {/* <Form.Label>File*</Form.Label> */}
                                <Form.Control
                                  type="file"
                                  name="documentFile"
                                  onChange={(e) => handleChange(e, "file")}
                                  isInvalid={!!formErrors["documentFile"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formErrors?.documentFile}
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="form-group mb-3"
                              controlId="formGroupAmount"
                            >
                              <Form.Control
                                type="date"
                                name="expiry"
                                min={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                  setExpiryDate(Date(e));
                                }}
                                isInvalid={!!formErrors["expiry"]}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors?.expiry}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form>
                          <Col xs={12} className="text-center">
                            <button
                              className="btn btn-primary py-2 px-5"
                              onClick={() =>
                                handleKycDocumentSubmit(
                                  KYC_DOCUMENTTYPE.EMIRATES_ID
                                )
                              }
                              disabled={isSubmittingDocument}
                            >
                              {isSubmittingDocument ? "Loading..." : "Submit"}
                            </button>
                          </Col>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                  <ListKycDocument
                    isLoading={isLoading}
                    kycRecord={kycRecord}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
    </div>
  );
};

export default KYC;

import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CasinoAfterIcon from "../assets/images/chip.png";
import DepositIcon1 from "../assets/images/savings.png";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/icons/telegramicon.png";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/icons/instagramicon.png";
import FACEBOOK from "../assets/images/icons/facebookicon.png";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gambling-commission.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import HomeIcon1 from "../assets/images/games-icon/footfav1.png";

import Accordion from "react-bootstrap/Accordion";

import Support from "../assets/images/games-icon/support.png";

import FAQImg from "../assets/images/faq/faq-img.svg";
import faqAnimation from "../assets/images/faq/faq-animation.svg";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import rng from "../assets/images/providers/RNG.png";
import plus from "../assets/images/providers/GamblingCOmmission.jpg";
import GamblingCOmmission from "../assets/images/providers/GamingResposibly.jpg";
import RGF from "../assets/images/providers/RGF.png";

import ProviderLogo4 from "../assets/images/providers/Spribe.jpg";
import ProviderLogo5 from "../assets/images/providers/netent.jpg";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";
import { Col, Row } from "react-bootstrap";
import PaymentMod1 from "../assets/images/providers/visa.png";
import PaymentMod12 from "../assets/images/providers/bitcoin.png";

import Twitter from "../assets/images/icons/twitter.png";

import i18next, { t } from "i18next";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "/betby",
    "Casioafter",
    "casino/ezugi",
    "deposit",
    "contactus",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer main">
      <div className="container">
        <div className="faq-section d-none">
          <div className="faq-image-section">
            <div className="faq-image">
              <img src={FAQImg} />
              <div className="FaqAnimation">
                <img src={faqAnimation} />
              </div>
            </div>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Why is Dial4win the #1 Online Games in India?
              </Accordion.Header>
              <Accordion.Body>
                Dial4win is the place to be if you want to experience the thrill
                of big Games wins. Our game catalogue offers hundreds of Jackpot
                Slots with a huge, life-changing winning potential. You can play
                the global top Jackpot Slots, or try your luck on a newly
                released slot. Just take your pick!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                What mobile online Games Games can I choose from?
              </Accordion.Header>
              <Accordion.Body>
                Enjoy the freedom of being able to play continuously on your
                desktop, tablet or mobile device whenever you like. All our
                games are optimised for mobile use. This means you can simply
                grab your preferred device and enjoy the whole range of our
                captivating Games and table games, such as: Andar Bahar, Teen
                Patti, Live Roulette, Game Shows, Live Baccarat, Live Poker &
                Live Blackjack.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can I get 24/7 help online at Dial4win?
              </Accordion.Header>
              <Accordion.Body>
                At Dial4win we strive to help you every step of the way to make
                your experience enjoyable and fun. If you need any assistance,
                please feel free to contact our dedicated live support team via
                online chat, or email.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What Welcome Offer and Games Bonuses will I get at Dial4win?
              </Accordion.Header>
              <Accordion.Body>
                Play and prosper with Dial4win Online Games. Here, at Dial4win,
                all loyal players get rewarded every step of the way.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Why is Dial4win the Best Gaming Site?
              </Accordion.Header>
              <Accordion.Body>
                Dial4win has one the best Sport Exchange which guarantees
                highest odds in the India Market. Dial4win caters 30+ sports &
                almost all the matches including IPL in India. We have various
                payment methods, games trusted by thousands of players, quality
                support - all for our fans!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Can I Play Games Online in India?
              </Accordion.Header>
              <Accordion.Body>
                If you think India is no place for gaming - you are wrong, as
                now you have the opportunity to play & earn real cash money
                playing at the comfort of your home.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Best Online Games Live Dealer Games
              </Accordion.Header>
              <Accordion.Body>
                There are a great number of other live games that provide a
                variety of advantageous features. They are as follows:
                <ul>
                  <li>
                    <b>Live Roulette</b>
                    <p>
                      Roulette, one of the most well-known and exciting Games
                      games played online anywhere in the world, is readily
                      accessible via our website. If you're a huge roulette
                      Indian lover, you'll adore our super-exciting live dealer
                      games for sure.
                    </p>
                  </li>
                  <li>
                    <b>Live Blackjack</b>
                    <p>
                      Blackjack is quite popular among Indians. And what could
                      possibly be more uplifting than playing a game of live
                      blackjack at a Games? We present this live game, which is
                      often referred to as "21," to guarantee that all those who
                      like Games have a fantastic and exciting gaming
                      experience.
                    </p>
                  </li>
                  <li>
                    <b> Live Baccarat</b>
                    <p>
                      Live Baccarat is just another one of the live games that
                      are really popular in our room. We welcome any new players
                      who are interested in emulating the excitement of a
                      real-life Games by providing them with this game, which
                      features high rollers and the most authentic land-based
                      Games experience possible.
                    </p>
                  </li>
                  <li>
                    <b>Live Three Card Poker</b>
                    <p>
                      The enthusiasm and devotion that Indians have for poker is
                      something that our online Games platform cares about. The
                      joy and excitement that players anticipate experiencing in
                      a traditional brick-and-mortar poker room was our
                      motivation for introducing the live three-card poker.
                    </p>
                  </li>
                </ul>{" "}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="providers_sec">
          <Row>
            <Col xs={12}>
              <div className="d-xl-flex align-items-center">
                <div className="heading mb-4 mb-xl-0 d-none">
                  <div className="text">
                    <h5>Top Providers</h5>
                    <p>Play Your favorite Games with Top Providers</p>
                  </div>
                </div>

                <div className="providers_logo d-flex  justify-content-between">
                  <div className="logo_box">
                    <img src={ProviderLogo1} alt="Provider Logo" />
                  </div>
                  {/* <div className="logo_box">
                    <img src={ProviderLogo2} alt="Provider Logo" />
                  </div> */}
                  <div className="logo_box">
                    <img src={ProviderLogo3} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={rng} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={plus} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={GamblingCOmmission} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={RGF} alt="Provider Logo" />
                  </div>
                  {/* <div className="logo_box">
                    <img src={ProviderLogo4} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo5} alt="Provider Logo" />
                  </div>
                  <div" className="logo_box">
                    <img src={ProviderLogo6} alt="Provider Logo" />
                  </div>
                  <div className="logo_box">
                    <img src={ProviderLogo7} alt="Provider Logo" />
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="providers_img d-none">
          <a href="#" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="#" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="#" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="#" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                : "#"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            Call Us {appDetails?.WHATS_APP_URL}
          </a>
        </div>

        <div className="footer-links">
          <div className="FooterPart">
            <h3>{t("about_us")}</h3>
            <ul>
              <li>
                <a href="/AboutUss">{t("about_us")}</a>
              </li>
              <li>
                <a href="/responsiblegame">{t("responsible_gaming")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("help")}</h3>
            <ul>
              <li>
                <a href="/contactuss">{t("contact_us")}</a>
              </li>
              {/* <li>
                <a href="/contactus">{t("contact_us")}</a>
              </li> */}
              <li>
                <a href="/terms">{t("terms_conditions")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("rules_regiulations")}</h3>
            <ul>
              <li>
                <a href="/privacypolicy ">{t("privacy_policy")}</a>
              </li>
              <li>
                <a href="/fairplay">{t("fair_play")}</a>
              </li>
              <li>
                <a href="/rules">{t("rules_regiulations")}</a>
              </li>
            </ul>
          </div>
          <div className="FooterPart">
            <h3>{t("social")}</h3>
            <ul>
              <li>
                <a
                  className="panel"
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FACEBOOK} alt="Telegram Icon" />
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href="https://twitter.com/Dial4W133"
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Twitter} alt="Telegram Icon" />
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                  <span>Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div className="d-flex justify-content-center mt-2 d-none">
          <img
            src={PaymentMod1}
            className="mx-3"
            loading="lazy"
            style={{ width: "100px" }}
            alt="Payment Otions"
          />
          <img
            src={PaymentMod12}
            className="mx-3"
            loading="lazy"
            style={{ width: "100px" }}
            alt="Payment Otions"
          />
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3 d-none">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"}
                target={appDetails.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails.FACEBOOK != "" ? appDetails.FACEBOOK : "#"}
                target={appDetails.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails.INSTATGRAM != "" ? appDetails.INSTATGRAM : "#"}
                target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "betby" ? "active" : ""}
                href="/betby"
              >
                <img src={Exchange} alt="Exchange Icon" />
                {/* <SportsIcon /> */}
                <span>Sportsbook</span>
              </a>
            </li>
            {/* <li>
              <a
                className={activePage === "Casioafter" ? "active" : ""}
                href="/Casioafter"
              > */}
            {/* <SportsIcon /> */}
            {/* <img src={CasinoAfterIcon} alt="Exchange Icon" />
                <span>Casino</span>
              </a>
            </li> */}
            <li className="active-foot">
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <img src={HomeIcon1} />
                {/* <HomeIcon /> */}
                {/* <span>Home</span> */}
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                className={activePage === "deposit" ? "active" : ""}
                href="/deposit"
              >
                {/* <GamesIcon /> */}
                {/* <CashierIcon /> */}
                <img src={DepositIcon1} alt="Exchange Icon" />

                <span>Deposit</span>
              </a>
            </li>
            {/* <li>
              <a
                className={activePage === "contactus" ? "active" : ""}
                href="/contactus"
              > */}
            {/* <CashierIcon /> */}
            {/* <img src={Support} />
                <span>Support</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <p>{appDetails?.FOOTER_TEXT || ""}</p>
    </footer>
  );
};

export default Footer;

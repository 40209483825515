import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import { t } from "i18next";

const AboutUs = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 class="sectionTitle"> {t("about_us")}</h2>
            <div className="terms-content">
              <p>{t("about_us_p_one")}</p>
              <p>{t("about_us_p_two")}</p>
              <p>{t("about_us_p_three")}</p>
              <p>{t("about_us_p_four")}</p>
              <p>{t("about_us_p_five")}</p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useEffect, useRef, useState } from "react";

import DepositIcon1 from "../../assets/images/games-icon/tabs-icon/Deposit.png";
import WithdrawIcon1 from "../../assets/images/games-icon/tabs-icon/Withhdraw.png";
import ProfileIcon1 from "../../assets/images/games-icon/tabs-icon/Profile.png";
import AccountIcon1 from "../../assets/images/games-icon/tabs-icon/Account-Statements.png";
import SignOut12 from "../../assets/images/games-icon/tabs-icon/Sign-Out.png";
import BonusIcon1 from "../../assets/images/games-icon/tabs-icon/Bonus.png";
import Refferal from "../../assets/images/games-icon/tabs-icon/Refferals.png";
import SportsBet from "../../assets/images/games-icon/tabs-icon/CasinoBet.png";
import CasinoBet from "../../assets/images/games-icon/tabs-icon/CasinoBet.png";
import KYC from "../../assets/images/games-icon/tabs-icon/KYCicon.png";
import Promotion from "../../assets/images/games-icon/tabs-icon/Promotion.png";
import ContactUs from "../../assets/images/games-icon/tabs-icon/Contact.png";
import search from "../../assets/images/games-icon/tabs-icon/kiskolocator.png";

import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/auth/actions";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import i18next, { t } from "i18next";

const LeftSideComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let scrollElement = useRef(null);

  const [activePage, setActivePage] = useState("");

  const sideItems = [
    "profile",
    "deposit",
    "withdraw",
    "bonus",
    "referral",
    "cashier",
    "casinobet",
    "kyc",
    "promotion",
    "contactus",
    // "sportsbets",
  ];
  const sideItemsName = [
    {
      name: t("profile"),
      url: "profile",
      // position: activePage === "profile" ? 1 : 2,
      src: ProfileIcon1,
    },
    {
      name: t("deposit"),
      url: "deposit",
      // position: activePage === "deposit" ? 1 : 2,
      src: DepositIcon1,
    },
    {
      name: t("withdraw"),
      url: "withdraw",
      // position: activePage === "withdraw" ? 1 : 3,
      src: WithdrawIcon1,
    },
    {
      name: t("bonus"),
      url: "bonus",
      // position: activePage === "bonus" ? 1 : 4,
      src: BonusIcon1,
    },
    {
      name: t("referral"),
      url: "referral",
      // position: activePage === "referral" ? 1 : 5,
      src: Refferal,
    },
    {
      name: t("transaction"),
      url: "cashier",
      // position: activePage === "cashier" ? 1 : 6,
      src: AccountIcon1,
    },
    // {
    //   name: "Sports Bet",
    //   url: "sportsbets",
    //   position: activePage === "sportsbets" ? 1 : 7,
    //   src: SportsBet,
    // },
    {
      name: t("history"),
      url: "casinobet",
      // position: activePage === "casinobet" ? 1 : 8,
      src: CasinoBet,
    },
    {
      name: t("kyc"),
      url: "kyc",
      // position: activePage === "kyc" ? 1 : 9,
      src: KYC,
    },
    {
      name: t("kiosk_locator"),
      url: "kiosklocator",
      // position: activePage === "kyc" ? 1 : 9,
      src: search,
    },
  ];
  useEffect(() => {
    const matchedValue = sideItemsName.find((f) =>
      location?.pathname.includes(f.url)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }

    handleScroll(location);
  }, [location]);

  const handleScroll = (location) => {
    const activeItem = sideItemsName.findIndex((itm) =>
      location.pathname.includes(itm.url)
    );

    scrollElement.current.scrollLeft =
      (scrollElement.current.scrollWidth / sideItemsName.length) * activeItem -
      70;
  };

  return (
    <div class="PageLeftBar">
      <div className="LeftBarSide d-none">
        <ul>
          <li>
            <a
              href="/profile"
              className={activePage === "profile" ? "sideBaractive" : ""}
            >
              <img src={ProfileIcon1} />
              <span>{t("profile")}</span>
            </a>
          </li>
          <li>
            <a
              href="/deposit"
              className={activePage === "deposit" ? "sideBaractive" : ""}
            >
              <img src={DepositIcon1} />
              <span>{t("deposit")}</span>
            </a>
          </li>
          {/* <li>
            <a href="/depositlist">
              <img src={DepositIcon1} />
              <span>Deposit List</span>
            </a>
          </li> */}
          <li>
            <a
              href="/withdraw"
              className={activePage === "withdraw" ? "sideBaractive" : ""}
            >
              <img src={WithdrawIcon1} />
              <span>{t("withdraw")}</span>
            </a>
          </li>
          <li>
            <a
              href="/bonus"
              className={activePage === "bonus" ? "sideBaractive" : ""}
            >
              <img src={BonusIcon1} />
              <span>{t("bonus")}</span>
            </a>
          </li>
          <li>
            <a
              href="/referral"
              className={activePage === "referral" ? "sideBaractive" : ""}
            >
              <img src={Refferal} />
              <span>{t("referral")}</span>
            </a>
          </li>
          <li>
            <a
              href="/cashier"
              className={activePage === "cashier" ? "sideBaractive" : ""}
            >
              <img src={AccountIcon1} />
              <span>{t("transaction")}</span>
            </a>
          </li>
          {/* <li>
            <a href="/cashier">
              <img src={SportsBet} />
              <span>Sports Bet</span>
            </a>
          </li> */}
          <li>
            <a
              href="/casinobet"
              className={activePage === "casinobet" ? "sideBaractive" : ""}
            >
              <img src={CasinoBet} />
              <span>{t("history")}</span>
            </a>
          </li>
          <li>
            <a
              href="/kyc"
              className={activePage === "kyc" ? "sideBaractive" : ""}
            >
              <img src={KYC} />
              <span> {t("kyc")}</span>
            </a>
          </li>

          <li onClick={() => dispatch(logoutUser())}>
            <a href="/">
              <img src={SignOut12} />
              <span>{t("sign_out")}</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="LeftBarSide">
        <ul ref={scrollElement}>
          {sideItemsName
            // .sort((a, b) => a.position - b.position)
            .map((item, key) => {
              return (
                <li key={key}>
                  <a
                    onClick={() => {
                      navigate(`/${item.url}`, {});
                    }}
                    className={
                      activePage.url === item.url ? "sideBaractive" : ""
                    }
                  >
                    <img src={item.src} />
                    <span>{item.name}</span>
                  </a>
                </li>
              );
            })}
          <li onClick={() => dispatch(logoutUser())}>
            <a href="/">
              <img src={SignOut12} />
              <span>{t("sign_out")}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default LeftSideComponents;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Offcanvas, Modal, Nav, Navbar } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Bonus from "../assets/images/bonus.png";

import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import LogoLightTheme from "../assets/images/LogoLightTheme.png";
import LogoDarkTheme from "../assets/images/LogoDarkTheme.png";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import EnglandFlag from "../assets/images/games-icon/uk.png";
import ProfileIcon1 from "../assets/images/games-icon/profile.png";
import AccountStatement1 from "../assets/images/games-icon/account-statement.png";
import SignOut1 from "../assets/images/games-icon/sign-out.png";
import i18next, { t } from "i18next";
import WithDraw from "../assets/images/games-icon/Withdraw.png";

import SportsIcon1 from "../assets/images/games-icon/Exchange.png";
import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import Evolution1 from "../assets/images/games-icon/Evolution.png";
import CashierIcon1 from "../assets/images/Aviator.png";

import Account from "../assets/images/icons/profilenew.png";
import Transaction from "../assets/images/icons/transaction1.png";
import Referral from "../assets/images/icons/referral.png";
import Promotion from "../assets/images/icons/promotion.png";
import Bonus1 from "../assets/images/icons/bonus1.png";
import SignOut from "../assets/images/icons/logout.png";
import dices from "../assets/images/games-icon/dices.png";
import soccerball from "../assets/images/games-icon/soccer-ball.png";
import casino1 from "../assets/images/games-icon/casino1.png";
import playingcards from "../assets/images/games-icon/playing-cards.png";
import Airplane from "../assets/images/games-icon/dices.png";

import soccerballhover from "../assets/images/games-icon/soccer-ball.png";
import casino1hover from "../assets/images/games-icon/EzugiNew.png";
import playingcardshover from "../assets/images/games-icon/Evolution.png";
import promotionhover from "../assets/images/games-icon/promotion.png";

import Fbicon from "../assets/images/head-icon/facebook.png";
import Twitter from "../assets/images/head-icon/twitter.png";
import Telegram from "../assets/images/head-icon/telegram.png";
import Instagram from "../assets/images/head-icon/instagram.png";
import Mobile from "../assets/images/head-icon/mobile.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";
import { RiHandCoinFill } from "react-icons/ri";
import Balance from "../assets/images/icons/dirham.png";
import ProfileIconNew from "../assets/images/icons/profilenew.png";
import DepositIcon from "../assets/images/icons/deposit.png";
import LanguageChange from "../views/components/LanguageChange";

// import SiteLogo from "../assets/images/logo/logo.png";

import BlogIcon from "../assets/images/icons/blogger.png";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const isBonus = numberWithCommas(wallet?.coins) || 0;
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [visible, setVisible] = useState(true);

  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "betby",
    "ezugi",
    "evolution",
    "casino/spribe/aviator",
    "promotion",
  ];
  // const headerLinks = ["casino", "sports"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const [dropShow, setDropShow] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  return (
    <>
      <header className="header aftrlgn">
        {visible ? (
          <div className="marqu">
            <marquee>
              <img src={StarIcon} alt="star icon" />
              {t("marquee")}
              <img src={StarIcon} alt="star icon" />
            </marquee>
            <span onClick={() => setVisible(!visible)}>
              <AiOutlineClose />
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="container-fluid headerlogin">
          <div className="d-flex justify-content-between align-items-center headerLoginSection">
            <div className="d-flex align-items-center ">
              <div className="logo">
                <a href="/home">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                  {/* <img src={SiteLogo} /> */}
                </a>
              </div>
              <Navbar expand="xl" className="d-none d-xl-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setShowToggle((s) => !s)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <Nav.Link
                      href="/betby"
                      className={activePage == "betby" ? "active" : ""}
                    >
                      <img src={soccerballhover} />
                      {t("sports")}
                    </Nav.Link>
                    <Nav.Link
                      href={CasinoProviders["ezugi"]?.href}
                      className={activePage == "ezugi" ? "active" : ""}
                    >
                      <img src={casino1hover} />
                      {t("ezugi")}
                    </Nav.Link>
                    <Nav.Link
                      href={CasinoProviders["evolution"]?.href}
                      className={activePage == "evolution" ? "active" : ""}
                    >
                      <img src={playingcardshover} />
                      {t("evolution")}
                    </Nav.Link>

                    <Nav.Link
                      href="/promotion"
                      className={activePage == "promotion" ? "active" : ""}
                    >
                      <img src={promotionhover} /> {t("promotions")}
                    </Nav.Link>
                    <Nav.Link href="/blog">
                      <img src={BlogIcon} className="" />
                      {t("blog")}
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>

            <div className="headerRight">
              <div className="">
                {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={EnglandFlag} />
                          English
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            English
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Arabic
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>{" "} */}
                <select
                  className="language-select btn-outline-secondary beforelogin-language"
                  value={window.localStorage.i18nextLng}
                  onChange={handleLanguageChange}
                >
                  <option value="en">Eng</option>
                  <option value="arab">عربى</option>
                </select>
                {/* <LanguageChange /> */}
              </div>
              <div className="header-balance d-none">
                <p className="m-0">
                  {t("balance")}:{" "}
                  <span>{numberWithCommas(wallet?.cash || 0)}</span>
                </p>
              </div>
              <div
                className="depositIconNew d-block d-md-none "
                onClick={() => navigate("/deposit")}
              >
                <img src={DepositIcon} alt="deposit icon" />
              </div>
              <div className="funds">
                <Dropdown align="end" className="header-balance ">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {/* <span className="d-none d-md-block">{t("balance")}:</span> */}
                    <span className="d d-none">Bal: </span>
                    {/* <img
                      src={Balance}
                      alt="balance img"
                      className="d-block d-md-none"
                    /> */}
                    <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    AED
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="tel:{user?.mobileno}">
                      <b>{user?.mstruserid}</b>
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item href="javascript:void(0)">
                      <h6>{t("balance")}</h6>
                      <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => setShowBetModal(true)}
                    >
                      <h6>{t("exposure")}</h6>
                      <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>{t("bonus")}</h6>
                      <span>INR {numberWithCommas(wallet?.coins)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/deposit" className="deposit">
                      Deposit
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item href="#" className="">
                      Bonus Upcoming
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                <ul>
                  {/* <li>
                      <div onClick={() => setShowBetModal(true)}>
                        <div>
                          Exp:{" "}
                          <span>{numberWithCommas(exposureAmount || 0)}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </a>
                    </li> */}
                  <li className="d-none d-md-block ">
                    <a href="/deposit" className="deposit">
                      {t("deposit")}
                    </a>
                  </li>
                </ul>
              </div>
              {/* <Button variant="primary menu-btn">
                <ProfileIcon />
                <MenuIcon />
              </Button> */}
              <Dropdown className="headerMainDropdown d-none" placement={"end"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {/* <img src={EnglandFlag} /> */}
                  <ProfileIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="">
                    <Button
                      variant="primary  w-100"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      {/* <ProfileIcon /> */}
                      <img src={ProfileIcon1} />
                      <span>{t("profile")}</span>
                    </Button>
                  </Dropdown.Item>
                  <Dropdown.Item href="">
                    <div
                      className="withdraw-style"
                      onClick={() => {
                        navigate("/withdraw");
                      }}
                    >
                      <Button variant="primary  w-100">
                        {" "}
                        <img src={WithDraw} /> {t("withdraw")}
                      </Button>
                    </div>
                  </Dropdown.Item>

                  {/* <Dropdown.Item href="#">
                      <div></div>
                      <Button
                        variant="primary  w-100"
                        onClick={() => dispatch(logoutUser())}
                      >
                        <LogoutIcon />
                        <img src={SignOut1} />
                        <span>Sign Out</span>
                      </Button>
                    </Dropdown.Item> */}
                  <li onClick={() => dispatch(logoutUser())}>
                    <a href="#">
                      {/* <LogoutIcon /> */}
                      <img src={SignOut1} />
                      {/* <span>Sign Out</span> */}
                      {t("sign_out")}
                    </a>
                  </li>
                </Dropdown.Menu>
              </Dropdown>

              <div className="user-drop">
                <div
                  className="user-button"
                  onClick={() => {
                    setDropShow(!dropShow);
                  }}
                >
                  <img src={ProfileIconNew} />
                </div>
                <div className={`user-content-section ${dropShow && `d-none`}`}>
                  <div className="user-content">
                    <ul>
                      <li>
                        <span>{user?.mstruserid}</span>
                        <a href="#">{user?.mobileno}</a>
                      </li>

                      <li>
                        <p className="m-0">
                          {t("balance")}
                          <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </p>
                      </li>
                      {isBonus != 0 && (
                        <li>
                          <div className="balance-amount">
                            <p>
                              {t("bonus")}
                              <span>
                                {numberWithCommas(wallet?.coins) || 0}
                              </span>
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                    <div className="user-other-option">
                      <ul>
                        <li>
                          <a href="/profile">
                            <img src={Account} />
                            {t("profile")}
                          </a>
                        </li>
                        <li>
                          <a href="/cashier">
                            {" "}
                            <img src={Transaction} />
                            {t("transaction")}
                          </a>
                        </li>
                        <li>
                          <a href="/bonus">
                            {" "}
                            <img src={Bonus1} />
                            {t("bonus")}
                          </a>
                        </li>
                        <li>
                          <a href="/referral">
                            <img src={Referral} />
                            {t("referral")}
                          </a>
                        </li>
                        <li>
                          <a href="/promotion">
                            <img src={Promotion} />
                            {t("promotions")}
                          </a>
                        </li>
                        <li onClick={() => dispatch(logoutUser())}>
                          <a href="/" className="signout">
                            <img src={SignOut} />
                            {t("sign_out")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Coins</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/deposit");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <BetIcon />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>Transaction</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <LogoutIcon />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  className="panel"
                  href={
                    appDetails.TELEGRAM != ""
                      ? appDetails.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>

              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <span className="notification-count">2</span>
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <buton
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </buton>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;

import React, { PureComponent } from "react";

import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import VisaImg from "../../../../assets/images/icons/foot-icon/Visa.png";
import MSImg from "../../../../assets/images/icons/foot-icon/MasterCard.png";
import VerfiedVisaImg from "../../../../assets/images/icons/foot-icon/verifiedbyvisa.png";
import MSSecureImg from "../../../../assets/images/icons/foot-icon/mastercard_securecode.png";

import { t } from "i18next";

const terms = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 class="sectionTitle"> {t("terms_conditions")}</h2>
            <div className="terms-content">
              <p>{t("terms_conditions_p_one")}</p>
              <div className="sub-points">
                <p>
                  <span>{t("terms_conditions_span_one")} -</span>{" "}
                  {t("terms_conditions_p_two")}
                </p>
                <p>
                  {" "}
                  <span>{t("terms_conditions_span_two")} -</span>{" "}
                  {t("terms_conditions_p_three")}
                </p>
                <p>
                  <span>{t("terms_conditions_span_three")} -</span>{" "}
                  {t("terms_conditions_p_four")}
                </p>
                <p>
                  {" "}
                  <span> {t("terms_conditions_span_four")} -</span>{" "}
                  {t("terms_conditions_p_five")}
                </p>
                <p>
                  {" "}
                  <span> {t("terms_conditions_span_five")} - </span>{" "}
                  {t("terms_conditions_p_six")}
                </p>
                <p>
                  {" "}
                  <span> {t("terms_conditions_span_six")}-</span>{" "}
                  {t("terms_conditions_p_seven")}
                </p>
                <p>
                  <span>{t("terms_conditions_span_seven")}-</span>
                  {t("terms_conditions_p_eight")}
                </p>{" "}
                <p>
                  {" "}
                  <span>{t("terms_conditions_span_eight")} -</span>{" "}
                  {t("terms_conditions_p_nine")}
                </p>
                <p>
                  <span>{t("terms_conditions_span_nine")} -</span>{" "}
                  {t("terms_conditions_p_ten")}
                </p>
                <p>
                  <span> {t("terms_conditions_span_ten")} - </span>
                  {t("terms_conditions_p_eleven")}
                </p>
                <p>
                  {t("terms_conditions_p_twelve")}

                  <ul>
                    <li>
                      <img src={VisaImg} alt="visa image" />
                    </li>
                    <li>
                      <img src={MSImg} alt="master card image" />
                    </li>
                    <li>
                      <img src={VerfiedVisaImg} alt="verified by visa image" />
                    </li>
                    <li>
                      <img src={MSSecureImg} alt="master card secure image" />
                    </li>
                  </ul>
                </p>
                <p>
                  <span> {t("terms_conditions_span_eleven")} -</span>
                  {t("terms_conditions_p_thirteen")}
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default terms;

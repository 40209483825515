import React, { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Container, Row, Col, Tabs, Tab, FormGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
// import CricketIcon from "../../assets/svg/CricketIcon";
// import FastGameIcon from "../../assets/svg/FastGameIcon";
// import LiveDealerIcon from "../../assets/svg/LiveDealerIcon";
// import GameShowIcon from "../../assets/svg/GameShowIcon";
// import RummyIcon from "../../assets/svg/RummyIcon";
import { casinoTabs, EzugiGames } from "../../lib/data";
import CasinoCard from "./CasinoCard";
import CasinoGames from "./CasinoGames";
import MatchInfoSlider from "./MatchInfoBox";
import MainSlider from "../../containers/MainSlider";
import LeftBarSports from "./LeftBarSports.js";
import notice from "../../assets/images/notice_icon.png";
import { useSelector } from "react-redux";
import TopBanner1 from "../../assets/images/banner-top-1.jpg";
import TopBanner2 from "../../assets/images/banner-top-2.jpg";
import Teenpatti from "../../assets/images/popular/Teenpatti.jpg";
import aviator from "../../assets/images/popular/aviator.jpg";
import Andarbahar from "../../assets/images/popular/Andarbahar.jpg";
import Cards from "../../assets/images/popular/32Cards.jpg";
import Crazytime from "../../assets/images/popular/Crazytime.jpg";
import DoubleBall from "../../assets/images/popular/DoubleBallRoulette.jpg";
import DragonTiger from "../../assets/images/popular/DragonTiger.jpg";
import italianroulette from "../../assets/images/popular/italianroulette.jpg";
import Monopoly from "../../assets/images/popular/Monopolylive.jpg";
import Lucky7 from "../../assets/images/popular/Lucky7.jpg";

import ProviderLogo1 from "../../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../../assets/images/providers/ProviderImg4.jpg";
import ProviderLogo5 from "../../assets/images/providers/ProviderImg5.jpg";
import ProviderLogo6 from "../../assets/images/providers/ProviderImg6.jpg";
import ProviderLogo7 from "../../assets/images/providers/ProviderImg7.jpg";
import { sportsSocket } from "../../utils/socketUtil";
import { setSportsData, setOddsData } from "../../redux/sports/actions";
import { useDispatch } from "react-redux";
import {
  getAppDetails,
  submitPublicUSerDetails,
} from "../../redux/app/actions";
import i18next, { t } from "i18next";
import esport from "../../assets/images/ESPORTS.png";
import livescasino from "../../assets/images/livescasinonew.png";
import lotterygames from "../../assets/images/lotterygames.png";
import slotsgames from "../../assets/images/slotsgames.png";
import card from "../../assets/images/card.png";
import ms from "../../assets/images/ms.png";
import home from "../../assets/images/home.png";
import sec from "../../assets/images/90sec.png";
import bottomimages from "../../assets/images/bottomimages.png";

import homee from "../../assets/images/homee.png";

import sportsImg from "../../assets/images/sportsbetting.png";
import AppRightImg from "../../assets/images/appmobile.png";
import dota from "../../assets/images/deposit-icon/dota.png";
import csgo from "../../assets/images/deposit-icon/csgo.png";
import HeroesoftheStorm from "../../assets/images/deposit-icon/HeroesoftheStorm.png";
import leagueoflegends from "../../assets/images/deposit-icon/leagueoflegends.png";
import Overwatch from "../../assets/images/deposit-icon/Overwatch.png";
import starcraft from "../../assets/images/deposit-icon/starcraft.png";
import valorant from "../../assets/images/deposit-icon/valorant.png";

import EvolutionProvider from "../../assets/images/live-casino/Evolution.png";
import EzugiProvider from "../../assets/images/live-casino/Ezugi.png";
import PragmaticProvider from "../../assets/images/live-casino/PragmaticPlay.png";
import VivoProvider from "../../assets/images/live-casino/VivoGaming.png";

import Cards3Icon1 from "../../assets/images/card/3Cards.png";
import Cards32Icon from "../../assets/images/card/32Cards.png";
import TeenPattiIcon from "../../assets/images/card/TeenPatti.png";

import Netent from "../../assets/images/slots/icons/Netent.png";
import OneTouch from "../../assets/images/slots/icons/OneTouch.png";
import Qtech from "../../assets/images/slots/icons/Qtech.png";
import RedTiger from "../../assets/images/slots/icons/RedTiger.png";
import Supernova from "../../assets/images/slots/icons/Supernova.png";
import esport1 from "../../assets/images/Esports1.png";
import fifa from "../../assets/images/fifa.png";
import cricket from "../../assets/images/cricket.png";
import basketball from "../../assets/images/basketball.png";
import icehockey from "../../assets/images/ice-hockey.png";

import football from "../../assets/images/football.png";
import TableTennis from "../../assets/images/TableTennis.png";
import rugby from "../../assets/images/rugby.png";
import punch from "../../assets/images/punch.png";
import volleyball from "../../assets/images/volleyball.png";
import sportsbet from "../../assets/images/games-icon/soccer-ball.png";
import esport3 from "../../assets/images/Esports3.png";
import livecasino from "../../assets/images/Livecasino.png";
import Slots from "../../assets/images/Slots.png";
import cardgames from "../../assets/images/CardGame.png";

import SportsBanner1 from "../../assets/images/banner/bonus-sign.jpg";
import SportsBanner2 from "../../assets/images/banner/refer-signup.jpg";
import SportsBanner3 from "../../assets/images/banner/deposit-bonus.jpg";

import QualityImg1 from "../../assets/images/deposit-icon/E-Wallet-pana.svg";
import QualityImg2 from "../../assets/images/deposit-icon/PaymentInformation-bro.svg";
import QualityImg3 from "../../assets/images/deposit-icon/Encryption-Security.svg";
import QualityImg4 from "../../assets/images/deposit-icon/gaming-platform.svg";

import SignBunusImg from "../../assets/images/modal/Mobilelogin-bro.png";

import Indiaflag from "../../assets/images/icons/UAE.png";
import WelocomeMobile from "../../assets/images/wemobile.png";
import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../utils/toaster.js";
import { APP_CONST } from "../../config/const.js";

const LandingPAge = () => {
  const { banners } = useSelector((state) => state.app);
  const { sportsData } = useSelector((state) => state.sports);
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const { appDetails } = useSelector((state) => state.app);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getAppDetails());
  }, []);

  const handleUserPublicDetails = async () => {
    if (mobileNumber) {
      const payload = {
        mobileNumber: mobileNumber,
      };
      setIsSubmitting(true);
      const response = await submitPublicUSerDetails(payload);
      if (response?.data?.error === false) {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Details submitted");
      }
      setIsSubmitting(false);
    }
    // window.open(
    //   appDetails?.WHATS_APP_URL != ""
    //     ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
    //     : "javascript:void(0)"
    // );
  };
  return (
    <main className="main LandingPage">
      {/* <div className="d-xl-none mt--14">
        <LeftBarSports />
      </div> */}
      <div className="top_banner_sec">
        <div className="mainBanner">
          <MainSlider banners={banners} />
        </div>
      </div>

      <div className="tabs_neww">
        <Tabs
          defaultActiveKey="sports"
          id="uncontrolled-tab-example"
          className=""
        >
          <Tab
            eventKey="sports"
            title={
              <span>
                <img src={sportsbet} /> {t("sports_gaming")}{" "}
              </span>
            }
          >
            <div className="container">
              <div className="platform-item">
                <div className="image_box">
                  <img src={sportsImg} />
                </div>
                <div className="sportsss">
                  <div className="sports_ev">
                    <h3>{t("sports_gaming")}</h3>
                    <p className="mt-5">{t("sports_gaming_paragraph")}</p>
                    <div className="e-sports-icons mb-3  ">
                      <ul className="new_iconssss">
                        <li>
                          <img src={cricket} />
                        </li>
                        <li>
                          <img src={basketball} />
                        </li>
                        <li>
                          <img src={icehockey} />
                        </li>

                        <li>
                          <img src={volleyball} />
                        </li>
                        <li>
                          <img src={football} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="esports"
            title={
              <span>
                <img src={esport3} /> {t("esports")}
              </span>
            }
          >
            <div className="container">
              <div className="platform-item">
                <div className="image_box">
                  <img src={esport} />
                </div>
                <div className="sportsss">
                  <div className="sports_ev">
                    <h3>{t("esports")} </h3>
                    <p className="mt-5">{t("esports_paragraph")}</p>
                    <div className="e-sports-icons">
                      <ul>
                        <li>
                          <img src={csgo} />
                        </li>
                        <li>
                          <img src={dota} />
                        </li>
                        <li>
                          <img src={HeroesoftheStorm} />
                        </li>
                        <li>
                          <img src={leagueoflegends} />
                        </li>
                        <li>
                          <img src={Overwatch} />
                        </li>
                        <li>
                          <img src={starcraft} />
                        </li>
                        <li>
                          <img src={valorant} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="casino"
            title={
              <span>
                <img src={livecasino} /> {t("live_games")}
              </span>
            }
          >
            <div className="container">
              <div className="platform-item">
                <div className="image_box">
                  <img src={livescasino} />
                </div>
                <div className="sportsss">
                  <div className="sports_ev">
                    <h3>{t("live_games")} </h3>
                    <p className="mt-5">{t("live_games_paragraph")}</p>
                    <div className="e-sports-icons">
                      <ul>
                        <li>
                          <img src={EvolutionProvider} />
                        </li>
                        <li>
                          <img src={EzugiProvider} />
                        </li>
                        <li>
                          <img src={PragmaticProvider} />
                        </li>
                        <li>
                          <img src={VivoProvider} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="cards"
            title={
              <span>
                <img src={cardgames} /> {t("cards_games")}
              </span>
            }
          >
            <div className="container">
              <div className="platform-item">
                <div className="image_box">
                  <img src={card} />
                </div>
                <div className="sportsss">
                  <div className="sports_ev">
                    <h3>{t("cards_games")}</h3>
                    <p className="mt-5">{t("cards_games_paragraph")}</p>
                    <div className="e-sports-icons">
                      <ul>
                        <li>
                          <img src={Cards3Icon1} />
                        </li>
                        <li>
                          <img src={Cards32Icon} />
                        </li>

                        <li>
                          <img src={TeenPattiIcon} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="slot"
            title={
              <span>
                <img src={Slots} /> {t("slots_games")}
              </span>
            }
          >
            <div className="container">
              <div className="platform-item">
                <div className="image_box">
                  <img src={slotsgames} />
                </div>
                <div className="sportsss">
                  <div className="sports_ev">
                    <h3>{t("slots_games")} </h3>
                    <p className="mt-5">{t("slots_games_paragraph")}</p>
                    <div className="e-sports-icons">
                      <ul>
                        <li>
                          <img src={Netent} />
                        </li>
                        <li>
                          <img src={OneTouch} />
                        </li>
                        <li>
                          <img src={Qtech} />
                        </li>
                        <li>
                          <img src={RedTiger} />
                        </li>
                        <li>
                          <img src={Supernova} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="depositSection">
        <div className="depositheading">
          <h2>{t("quality_services")} </h2>
        </div>
        <div className="d-flex depositBottmScetion">
          <div className="bottom_cont">
            <div className="heading_img">
              <h5>{t("fast_deposit_transfer")}</h5>
            </div>

            <p>{t("fast_deposit_transfer_paragraph")}</p>

            <div className="heading_img">
              <h5>{t("mass_competition_types")}</h5>
            </div>
            <p>{t("mass_competition_types_paragraph")}</p>

            <div className="heading_img">
              <h5> {t("encryption_security_management")}</h5>
            </div>
            <p>{t("encryption_security_management_paragraph")}</p>

            <div className="heading_img">
              <h5>{t("choose_from_three_terminals")} </h5>
            </div>
            <p>{t("choose_from_three_terminals_paragraph")}</p>

            <div className="heading_img mt-4 ">
              <h1 className="responsive_headings">
                {t("Welcome_to_Dial4win")}
              </h1>
            </div>
            <p className="mt-3">{t("Welcome_to_Dial4win_paragraph")}</p>

            <div className="heading_img ">
              <h2 className="responsive_headings">
                {t("Dive_into_the_World")}
              </h2>
            </div>
            <p className="mt-2">{t("Dive_into_the_World_para")}</p>

            <div className="heading_img ">
              <h2 className="responsive_headings">{t("Discover_the_Best")}</h2>
            </div>
            <p className="mt-2">{t("Why_choose_Dial4win")}</p>
            <p className="mt-2">{t("Extensive_Game_Library")}</p>
            <p className="mt-2">{t("Friendly_Interface")}</p>
            <p className="mt-2">{t("24/7_Customer_Support")}</p>
            <p className="mt-2">{t("Generous_Bonuses")}</p>
            <p className="mt-2">{t("Join_Dial4win_today")}</p>

            <div className="heading_img ">
              <h2 className="responsive_headings">{t("Play_at_the_Best")}</h2>
            </div>
            <p className="mt-2">{t("Play_at_the_Best_para")}</p>

            <div className="heading_img ">
              <h3 className="responsive_headings">
                {t("Online_Betting_Sites")}
              </h3>
            </div>
            <p className="mt-2">{t("When_it_comes_to")}</p>

            <div className="heading_img ">
              <h3 className="responsive_headings">{t("Betting_Sites")}</h3>
            </div>
            <p className="mt-2">{t("Betting_Sites_para")}</p>

            <div className="heading_img ">
              <h3 className="responsive_headings">{t("UAE_Betting")}</h3>
            </div>
            <p className="mt-2">{t("UAE_Betting_para")}</p>
            <p className="mt-2">{t("Localized_Experience")}</p>
            <p className="mt-2">{t("Secure_Transactions")}</p>
            <p className="mt-2">{t("Exclusive_Bonuses")}</p>
            <p className="mt-2">{t("Join_Dial4win_and")}</p>

            <div className="heading_img ">
              <h3 className=" responsive_headings">{t("FAQs")}</h3>
            </div>
            <p className="mt-2">{<strong>{t("Q1")}</strong>}</p>
            <p className="mt-2">{t("Q1_Ans")}</p>

            <p className="mt-2">{<strong>{t("Q2")}</strong>}</p>
            <p className="mt-2">{t("Q2_Ans")}</p>

            <p className="mt-2">{<strong>{t("Q3")}</strong>}</p>
            <p className="mt-2">{t("Q3_Ans")}</p>

            <p className="mt-2">{<strong>{t("Q4")}</strong>}</p>
            <p className="mt-2">{t("Q4_Ans")}</p>
          </div>

          <div className="bottom_img">
            <img src={bottomimages} />
          </div>
        </div>
      </div>

      <div className="AppdownloadSection">
        <div className="depositheading">
          <h2>{t("app_download")} </h2>
        </div>
        <div className="welcome-offer">
          <div className="container-fluid">
            <Row>
              <Col md="6" xs="12">
                <div className="WelcomeContentSection">
                  <div className="welcomeContent">
                    <h4>{t("app_download_heading")}</h4>
                    <h3>{t("app_download_paragraph")}</h3>
                  </div>

                  <div className="WelcomeForm">
                    <p>{t("welcom_text5")}</p>
                    <Form>
                      <FormGroup
                        className=" position-relative mb-3"
                        controlId="formBasicPassword"
                      >
                        <PhoneInput
                          country={"ae"}
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          // onlyCountries={["in", "np"]}
                          inputStyle={{}}
                          onChange={(phone) => {
                            setMobileNumber(phone);
                          }}
                        />
                      </FormGroup>

                      <div
                        onClick={handleUserPublicDetails}
                        className={isSubmitting ? "Issubmit" : "panel"}
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        {isSubmitting ? "Submitting..." : "Get Link"}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="d-md-block d-none">
                  <div className="WelcomeMobileImg">
                    <img src={WelocomeMobile} alt="WelocomeMobile" />
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-md-none d-block">
              <div className="WelcomeMobileImg">
                <img src={WelocomeMobile} alt="WelocomeMobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sports_banner_sec">
        <h2>Promotion </h2>
        <p>
          There are all the Dial4win you want, bringing you a rich gaming
          experience
        </p>
        <Row>
          <Col xs={4}>
            <a href="#" className="img_box" onClick={handleShow}>
              <img src={SportsBanner1} alt="Sport Banner" />
            </a>
            <Modal
              show={show}
              onHide={handleClose}
              className="login-modal SignBonusModal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="Sign-up-bonus">
                  <div className="signup-img">
                    <img src={SignBunusImg} alt="signup bonus" />
                  </div>
                  <div className="signup-description">
                    <h3>Sign Up Coins (5 AED)</h3>
                    <p>How to Claim a Signup Bonus of INR 200 at dialwin</p>
                    <ul>
                      <li>Click on Claim Now</li>
                      <li>Fill the form and enter Promo Code – SK200</li>
                      <li>
                        An Instant Bonus of INR 200 will be credited into your
                        account.
                      </li>
                    </ul>
                    <p>
                      Yes, It is that easy to claim a free bonus amount of INR
                      200 at Dial4win.
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
          <Col xs={4}>
            <a href="#" className="img_box">
              <img src={SportsBanner2} alt="Sport Banner" />
            </a>
          </Col>
          <Col xs={4}>
            <a href="#" className="img_box">
              <img src={SportsBanner3} alt="Sport Banner" />
            </a>
          </Col>
        </Row>
      </div>
    </main>
  );
};
export default LandingPAge;

import io from "socket.io-client";
import { getLocalToken } from "./axiosService";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/public/",
  transports: ["websocket", "polling"],
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const socketFunc = (token) =>
  io(process.env.REACT_APP_SOCKET_URL, {
    path: "/public/",
    transports: ["websocket", "polling"],
    auth: {
      token: token || localStorage.getItem("token"),
    },
  });

/**
 *
 * @param {[eventName: string, payload:any]} inputData
 * @returns {*}
 * @description timesout in 10 seconds
 */
export const asyncSocketEmit = (...inputData) => {
  return new Promise(function (resolve, reject) {
    socketFunc(getLocalToken()).emit(...inputData, (res) => {
      if (res?.data) {
        resolve(res?.data);
      }
      if (res.error) {
        reject(res.error);
      }
    });
    // setTimeout(reject("request timeout"), 10_000);
  });
};

// export const sportsSocket = io(process.env.REACT_APP_SPORTS_SOCKET, {
//   path: "/public/",
//   transports: ["websocket", "polling"],
// });

// export function asyncSocketEmit(...inputData) {
//   return new Promise(function (resolve, reject) {
//     socket.emit(...inputData, (result) => {
//       resolve(result);
//     });
//     // socket.on(eventName, (result) => {
//     //   socket.off(eventName);
//     //   resolve(result);
//     // });
//     setTimeout(reject, 1000);
//   });
// }

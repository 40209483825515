import React, { PureComponent } from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

import { t } from "i18next";

function BeforeResponsibleGame() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 className="sectionTitle">{t("responsible_gaming")}</h2>
            <div className="responsible-game sub-points">
              <p>{t("responsible_gaming_p_one")}</p>
              <p>
                <span>{t("responsible_gaming_span_one")} -</span>{" "}
                {t("responsible_gaming_p_two")}
              </p>
              <p>
                <span> {t("responsible_gaming_span_two")} - </span>
                {t("responsible_gaming_p_three")}
              </p>
              <p>
                <span>{t("responsible_gaming_span_three")} -</span>{" "}
                {t("responsible_gaming_p_four")}
              </p>
              <p>
                <span>{t("responsible_gaming_span_four")} -</span>{" "}
                {t("responsible_gaming_p_five")}
              </p>
              <p>
                <span>{t("responsible_gaming_span_five")} -</span>{" "}
                {t("responsible_gaming_p_six")}
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
}

export default BeforeResponsibleGame;

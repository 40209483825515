import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import { t } from "i18next";

function AboutUss() {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main pagecontent">
          <div className="container">
            <h2 class="sectionTitle"> {t("about_us")}</h2>
            <div className="terms-content">
              {/* <p>
                {" "}
                Welcome to our Gaming website! We are dedicated to providing
                you with an exceptional online Gaming experience. Our platform
                offers a wide range of exciting Gaming options across various
                sports and events, ensuring there's something for every sports
                enthusiast. Whether you're a passionate fan or a casual
                gamester, our user-friendly interface and intuitive features
                make it easy to navigate and place your bets with confidence.
              </p> */}

              <p>{t("about_us_p_one")}</p>
              <p>{t("about_us_p_two")}</p>
              <p>{t("about_us_p_three")}</p>
              <p>{t("about_us_p_four")}</p>
              <p>{t("about_us_p_five")}</p>
              {/* <p>
                Join our Gaming website today and embark on an exciting
                journey filled with thrilling sports action, lucrative Gaming
                opportunities, and a community of fellow sports enthusiasts.
                Place your bets responsibly and let the excitement begin!
              </p> */}
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUss;

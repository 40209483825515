import React, { useState, useEffect } from "react";
import { getPlayerKycRecordAction } from "../../../../redux/user/actions";
import moment from "moment";

const ListKycDocument = ({ kycRecord, isLoading }) => {
  return (
    <main className="main">
      <div className="container table-responsive">
        <h4>Account Details</h4>
        <table className="table text-white WithdrawTable">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Document Type</th>
              {/* <th scope="col">Type</th> */}
              <th scope="col">Document File Type</th>
              <th scope="col">Status</th>
              <th scope="col">Document Id</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading
              ? Array.isArray(kycRecord) &&
                kycRecord.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>

                      <td style={{ color: "#fff", cursor: "pointer" }}>
                        {item?.type}
                      </td>
                      {/* <td>{item?.type}</td> */}
                      <td>{item?.docFileType}</td>
                      <td>{item?.status}</td>
                      <td>{item?.documentId}</td>
                      <td>
                        {moment(item?.createdAt).format("DD-MM-YY h:mm A")}
                      </td>
                    </tr>
                  );
                })
              : "Loading..."}
          </tbody>
        </table>
        {/* <PaginationCard pagination={pagination} count={bankDetails?.count} /> */}
      </div>
    </main>
  );
};
export default ListKycDocument;
